import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-be6775e0")
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "tooltip" }
const _hoisted_3 = { class: "button" }
const _hoisted_4 = { class: "picture" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  key: 0,
  class: "caption"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["MultiplePictureChoiceButton", {selected: _ctx.answer.selected}]),
    title: _ctx.answer.text
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.answer.text), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.assetPath)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.assetPath + _ctx.answer.picture,
              alt: _ctx.answer.text
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showCaption)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.answer.text), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}