/*
  Handles communication with the API.
  Is a token needed for the public API? XSS seems not applicable
 */

import mock from "../assets/mock.json";
import {QuestionComponents} from "@/interfaces/QuestionComponents";

const ApiService = {
    endpoint: '',

    get(url: string): unknown {
        const apimock = mock;
        switch (url) {
            case 'quiz/1':
                apimock.questions.map((question) => {
                    question.questionType = question.questionType as keyof typeof QuestionComponents
                })

                return apimock;
        }
    },
    post(url: string, sendData: unknown): Promise<unknown> {
        const requestOptions = {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(sendData)
        };
        return fetch(this.endpoint + url, requestOptions)
            .then(response => response.json())
    },
    setEndpoint(endpoint: string): void {
        this.endpoint = endpoint;
    }
}

export default ApiService
