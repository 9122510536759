import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4e643302")
const _hoisted_1 = { class: "MultipleChoice" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultipleChoiceButton = _resolveComponent("MultipleChoiceButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
      return (_openBlock(), _createBlock(_component_MultipleChoiceButton, {
        key: answer.id,
        answer: answer,
        onClick: ($event: any) => (this.$emit('answerClicked', answer.id))
      }, null, 8, ["answer", "onClick"]))
    }), 128))
  ]))
}