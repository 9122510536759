import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-39d4a256")
const _hoisted_1 = ["title"]
const _hoisted_2 = ["src", "alt"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["BinaryChoiceButton", {selected: _ctx.answer.selected}]),
    title: _ctx.answer.text
  }, [
    (_ctx.assetPath)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.assetPath + _ctx.answer.picture,
          alt: _ctx.answer.text
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}