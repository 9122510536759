
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: 'WineComponent',
  inject: ['assetPath'],
  props: {
    wine: {
      type: Object as any
    }
  }
});
