
import {defineComponent, PropType} from "vue";
import MultipleChoice from "@/components/MultipleChoice.vue";
import MultiplePictureChoice from "@/components/MultiplePictureChoice.vue";
import BinaryChoice from "@/components/BinaryChoice.vue";
import {QuestionComponents} from "@/interfaces/QuestionComponents";
import {QuestionType} from "@/interfaces/QuestionType";
import StartChoice from "@/components/StartChoice.vue";
import SwipeChoice from "@/components/SwipeChoice.vue";
import ResultChoice from "@/components/ResultChoice.vue";
import ProfileChoice from "@/components/ProfileChoice.vue";

export default defineComponent({
  name: 'Question',
  components: {MultipleChoice, MultiplePictureChoice, BinaryChoice, StartChoice, SwipeChoice, ResultChoice, ProfileChoice},
  inject: ['assetPath'],
  emits: ['answerClicked'],
  props: {
    question: {
      type: Object as PropType<QuestionType>
    }
  },
  computed: {
    questionType(): string {
      return QuestionComponents[(this.question as QuestionType).questionType]
    }
  },
  methods: {
    displayFrame(q: QuestionComponents):boolean {
      return q == QuestionComponents.SwipeChoice
    }
  }
});
