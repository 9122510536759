import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Quiz = _resolveComponent("Quiz")!

  return (_openBlock(), _createBlock(_component_Quiz, {
    "asset-path": _ctx.assetPath,
    "api-endpoint": _ctx.apiEndpoint,
    "shop-url": _ctx.shopUrl
  }, null, 8, ["asset-path", "api-endpoint", "shop-url"]))
}