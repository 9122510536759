
import {defineComponent, PropType} from "vue";
import BinaryChoiceButton from "@/components/BinaryChoiceButton.vue";
import {AnswerType} from "@/interfaces/AnswerType";

export default defineComponent({
  name: 'BinaryChoice',
  components: {BinaryChoiceButton},
  emits: ['answerClicked'],
  props: {
    answers: {
      type: Object as PropType<AnswerType[]>
    }
  }
});
