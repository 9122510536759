import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ba8e61f4")
const _hoisted_1 = { class: "progressbar" }
const _hoisted_2 = { class: "prozent" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "progress",
        style: _normalizeStyle({width: Math.floor((_ctx.progress-1)/_ctx.overall*100) + '%'})
      }, null, 4)
    ]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(Math.floor((_ctx.progress-1)/_ctx.overall*100)) + " %", 1)
  ], 64))
}