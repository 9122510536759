
import {defineComponent, PropType} from "vue";
import {AnswerType} from "@/interfaces/AnswerType";

export default defineComponent({
  name: 'StartChoice',
  emits: ['answerClicked'],
  props: {
    answers: {
      type: Object as PropType<AnswerType[]>
    }
  }
});
