
import {defineComponent} from "vue";

export default defineComponent({
  name: 'Navigation',
  props: {
    prevVisible: {
      type: Boolean
    }
  },
  emits: ['prevQuestion']
});
