
import {defineComponent, PropType} from "vue";
import MultiplePictureChoiceButton from "@/components/MultiplePictureChoiceButton.vue"
import {AnswerType} from "@/interfaces/AnswerType";

export default defineComponent({
  name: 'MultiplePictureChoice',
  emits: ['answerClicked'],
  components: { MultiplePictureChoiceButton },
  props: {
    answers: {
      type: Object as PropType<AnswerType[]>
    }
  }
});
