/*
  Gets the answered Questions for a loggedIn User
  Handles storage and retrieval of answers and auth hashes
*/

/* eslint-disable */
// @ts-nocheck
const UserService = {
    setSessionId(sessionId: string): void {
        window.localStorage.setItem('@tt_session_id', sessionId)
    },
    getSessionId(): string|null {
        const sessionId = window.localStorage.getItem('@tt_session_id');
        if(sessionId){
            return sessionId
        }
        return null
    },
    setType(type: string): void {
        window.localStorage.setItem('@tt_customer_type', type)
        window.localStorage.setItem('@tt_rating_token', this.getSessionId())
    },
    getCid(): string {
        let cid = '';
        if(typeof window.tt !== "undefined"){
            cid = window.tt.cid;
        }
        return cid;
    },
    getHash(): string {
        let hash = '';
        if(typeof window.tt !== "undefined"){
            hash = window.tt.hash;
        }
        return hash;
    }
}

export default UserService
