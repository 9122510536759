
import {defineComponent, PropType} from "vue";
import {Chart, ChartItem, registerables} from "chart.js";
import {MatchType} from "@/interfaces/MatchType";
import {AnswerType} from "@/interfaces/AnswerType";

let colors = [
  {
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
    borderColor: 'rgb(255, 99, 132)',
  },
  {
    backgroundColor: 'rgba(54, 162, 235, 0.2)',
    borderColor: 'rgb(54, 162, 235)',
  },
  {
    backgroundColor: 'rgba(235,211,54,0.2)',
    borderColor: 'rgb(235,217,54)',
  },
  {
    backgroundColor: 'rgba(54,235,99,0.2)',
    borderColor: 'rgb(87,235,54)',
  },
]

export default defineComponent({
  name: 'ProfileChoice',
  emits: ['answerClicked'],
  props: {
    matches: {
      type: Object as PropType<MatchType[]>
    },
    answers: {
      type: Object as PropType<AnswerType[]>
    }
  },
  created() {
    Chart.register(...registerables)
  },
  data() {
    return {
      match: [
        ({'name': '', 'points': [0,0,0,0,0]} as MatchType)
      ]
    }
  },
  mounted(){
    this.match = this.matches as MatchType[];

    let ctx = document.getElementById('radar') as ChartItem

    new Chart(ctx, {
      type: 'radar',
      data: {
        labels: ['Süße', 'Säure', 'Alkohol', 'Körper', 'Fruchtigkeit'],
        datasets: [
          {
            label: 'Dein Geschmacksprofil',
            data: (this.matches as MatchType[])[0].points,
            backgroundColor: colors[0].backgroundColor,
            borderColor: colors[0].borderColor
          }
        ]
      },
      options: {
        responsive: false,
        scales: {
          r: {
            angleLines: {
              display: true
            },
            min: 0,
            max: 5
          }
        }
      }
    })
  },
  watch: {
    matches: function(newVal: MatchType[]) { // watch it
      console.log("Watch matches");
      console.log(newVal);
      this.match = newVal;

      let ctx = document.getElementById('radar') as ChartItem

      new Chart(ctx, {
        type: 'radar',
        data: {
          labels: ['Süße', 'Säure', 'Alkohol', 'Körper', 'Fruchtigkeit'],
          datasets: [
            {
              label: 'Dein Geschmacksprofil',
              data: newVal[0].points,
              backgroundColor: colors[0].backgroundColor,
              borderColor: colors[0].borderColor
            }
          ]
        },
        options: {
          responsive: false,
          scales: {
            r: {
              angleLines: {
                display: true
              },
              min: 0,
              max: 5
            }
          }
        }
      })
    }
  }
});
