/*
  Gets the questions fromm the server
  This is done when the quiz is loaded,
  it checks if there is a new version,
  otherwise serve cached question.
 */

import ApiService from "./ApiService";
import {QuizType} from "@/interfaces/QuizType";
import UserService from "@/services/UserService";
import {ResponseType} from "@/interfaces/ResponseType";
import {QuizDataType} from "@/interfaces/QuizDataType";
import {RequestType} from "@/interfaces/RequestType";
import {MatchesRequestType} from "@/interfaces/MatchesRequestType";
import {MatchesResponseType} from "@/interfaces/MatchesResponseType";

const QuizService = {
    assetUrl: '',

    getQuiz(quizId: number): QuizType {
        return ApiService.get('quiz/' + quizId) as QuizType;
    },
    sendAnswer(quizData: QuizDataType[]): Promise<ResponseType> {
        const sendData: RequestType = {
            sessionId: UserService.getSessionId(),
            quizData: quizData,
            cid: UserService.getCid(),
            hash: UserService.getHash()
        };
        return ApiService.post('', sendData) as Promise<ResponseType>;
    },
    getAssetPath(asset: string): string {
        console.log(this.assetUrl)
        return this.assetUrl + asset
    },
    setAssetUrl(assetUrl: string): void {
        this.assetUrl = assetUrl;
    },
    getMatch(quizData: QuizDataType[]): Promise<MatchesResponseType> {
        const aromaIds: number[] = [];
        const mealIds: number[] = [];
        const taste: {[k: string]: number} = {};
        const colors: number[] = [];

        for (const question of quizData) {
            if(question.questionId === 2){
                taste.sweetness = question.answerId - 2;
            }else if(question.questionId === 3){
                taste.sweetness = question.answerId - 7;
            }else if(question.questionId === 5){
                taste.sourness = question.answerId - 15;
            }else if(question.questionId === 6){
                taste.sourness = question.answerId - 20;
            }else if(question.questionId === 7){
                taste.sourness = question.answerId - 25;
            }else if(question.questionId === 8){
                taste.alcohol = ((question.answerId - 31) * 2) + 1;
            }else if(question.questionId === 10) {
                taste.body = question.answerId - 35;
            }else if(question.questionId === 11) {
                taste.body = question.answerId - 40;
            }else if(question.questionId === 12) {
                taste.fruitiness = question.answerId - 45;
            }else if(question.questionId === 14) {
                taste.tanin = question.answerId - 52;
            }else if(question.questionId === 15) {
                taste.tanin = question.answerId - 57;
            }else if(question.questionId >= 20 && question.questionId <= 41 && question.score > 0) {
                aromaIds.push(question.score)
            }else if((question.questionId >= 42 && question.questionId <= 57) ||
                (question.questionId >= 60 && question.questionId <= 63)) {
                mealIds.push(question.score);
            }else if(question.questionId === 17) {
                if(question.score === 1){
                    colors.push(1, 2, 3);
                }else if(question.score === 2){
                    colors.push(5, 6, 7, 8);
                }else if(question.score === 3){
                    colors.push(1, 2, 3, 4, 5, 6, 7, 8, 9);
                }
            } else if(question.questionId === 58 && question.answerId === 189){
                mealIds.push(-1)
            }

        }

        const sendData: MatchesRequestType = {
            aromaIds: aromaIds,
            mealIds: mealIds,
            taste: taste,
            colors: colors
        }

        return ApiService.post('tailor_api.php', sendData) as Promise<MatchesResponseType>;
    }
}

/*
aromas
aromas_grapes
meals_grapes
meals_categories
Meals = Neu
wines = Weinsorte
grapes = Rebsorte

Ingredients = alt!
wines = alt!


Rote Kirsche 117
Schwarze Johannisbeere 35
Himbeere 114
Backgew 137
Paprika 148
Holz 76
Vanille 77
Marmelade 45
 */

export default QuizService
