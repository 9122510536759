import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5d21c4d3")
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "circle" }
const _hoisted_3 = { class: "checkmark" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["MultipleChoiceButton", {selected: _ctx.answer.selected}]),
    title: _ctx.answer.text
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.answer.selected)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "●"))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.answer.text), 1)
  ], 10, _hoisted_1))
}