
import {defineComponent, PropType} from "vue";
import {AnswerType} from "@/interfaces/AnswerType";

export default defineComponent({
  name: 'MultipleChoiceButton',
  props: {
    answer: {
      type: Object as PropType<AnswerType>
    }
  }
});
