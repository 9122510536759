
import {defineComponent, PropType} from "vue";
import {AnswerType} from "@/interfaces/AnswerType";

export default defineComponent({
  name: 'MultiplePictureChoiceButton',
  props: {
    answer: {
      type: Object as PropType<AnswerType>
    },
    showCaption: {
      type: Boolean
    }
  },
  inject: ['assetPath'],
});
